import axios, { AxiosProgressEvent } from "axios";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { VideoRequest } from "../components/GenerateVideo/types";
import { UpscalingSettings } from "./request-upscale";
import libraryJson from "./library-json";
import * as Sentry from "@sentry/react";
import {
  AudioUpdateRequest,
  SubscriptionItem,
  UpdateUserDetails,
} from "../components/Subscription/types";
import { RestyleRequest } from "./request-restyle";
import toast from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_MUZE_API_HOST || "http://localhost:8002";

const GET = "get";
const POST = "post";
const PUT = "put";
const DELETE = "delete";

const STOP_EXECUTION = `${BASE_URL}/pause-execution`;
// const STOP_EXECUTION = `http://localhost:8002/pause-execution`;

// AUTHENTICATION
const AUTH_URL = `${BASE_URL}/token`;

// VIDEO
const VIDEO_META_L_URL = `${BASE_URL}/video/metadata_long_single`;
const VIDEO_META_URL = `${BASE_URL}/video/metadata`;
const VIDEO_ORIENTATIONS_URL = `${BASE_URL}/ui/widgets/orientation`;
const VIDEO_PROMPTS_URL = `${BASE_URL}/video/keyframe_prompts`;
const VIDEO_LIMITS_URL = `${BASE_URL}/admin/user_limits`;
const VIDEO_PARAMS_URL = `${BASE_URL}/video/build_params`;
const VIDEO_KEYFRAME_URL = `${BASE_URL}/video/keyframe_prompts`;
const VIDEO_TRANSITIONS_URL = `${BASE_URL}/effects/transitions`;
const VIDEO_GENERATE_URL = `${BASE_URL}/video/generate`;
const VIDEO_PERMISSION_URL = `${BASE_URL}/video/generate_permission`;
const VIDEO_DELETE_URL = `${BASE_URL}/video/delete`;
const VIDEO_UPSCALE_URL = `${BASE_URL}/video/upscale`;
const VIDEO_UPSCALE_URL_RESTYLE = `${BASE_URL}/video/upscale/restyle`;
const VIDEO_UPSCALE_ASSETS = `${BASE_URL}/video/upscale-metadata`;
const PLAN_SUBSCRIPTION_INFO = `${BASE_URL}/plan`;
const PLAN_SUBSCRIPTION_INFO_STRIPE = `${BASE_URL}/plan-stripe`;
const CANCEL_PLAN = `${BASE_URL}/cancel_subscription`;
const PLAN_SUBSCRIPTION_UPGRADE = `${BASE_URL}/upgrade_subscription`;
const PLAN_SUBSCRIPTION_DOWNGRADE = `${BASE_URL}/downgrade_subscription`;
const CHANGE_SUBSCRIPTION_TRIALING = `${BASE_URL}/change_subscription_trialing`;

// STYLE
const STYLES_URL = `${BASE_URL}/styles/thumbnail_links`;
const STYLES_GENERATE_URL = `${BASE_URL}/styles/generate_thumbnails`;
const SETTINGS_STYLES = `${BASE_URL}/styles/settings`;

// AUDIO
const AUDIO_ASSETS_URL = `${BASE_URL}/audio/assets`;
const AUDIO_ASSETS_S_URL = `${BASE_URL}/audio/asset_single`;
const AUDIO_SEGMENTATION_URL = `${BASE_URL}/audio/song_segmentation`;
const AUDIO_ANALISYS_URL = `${BASE_URL}/audio/stem_analysis`;
const AUDIO_GENERATE_URL = `${BASE_URL}/audio/stem_camera_mappings`;
const AUDIO_DELETE_URL = `${BASE_URL}/audio`;
const AUDIO_LIST_URL = `${BASE_URL}/audio/list_bubble_assets`;
const AUDIO_INGEST_URL = `${BASE_URL}/audio/ingest`;
const AUDIO_TRANSCRIPT_URL = `${BASE_URL}/audio/transcription`;
const AUDIO_TRANSC_ALIGN_URL = `${BASE_URL}/audio/transcription-aligned`;

// DREAMBOOTH
const DREAMBOOTH_TRAIN_URL = `${BASE_URL}/dreambooth/train`;
const DREAMB_LIST_MODELS_URL = `${BASE_URL}/dreambooth/metadata`;
const DREAMBOOTH_DELETE_URL = `${BASE_URL}/dreambooth/delete`;
const DREAMBOOTH_PREVIEW_URL = `${BASE_URL}/dreambooth/metadata/previews`;
const DREAMBOOTH_PREVIEW_DEFAULT_URL = `${BASE_URL}/dreambooth/metadata/previews/default`;

// USER
const USER_CREATE_URL = `${BASE_URL}/admin/new_user`;
const REGISTER_CUSTOMER_CREDITS_URL = `${BASE_URL}/plan`;
const REGISTER_CUSTOMER_CREDITS_URL_STRIPE = `${BASE_URL}/plan-stripe`;
const GET_USER_PLAN = `${BASE_URL}/user-credits`;
const USER_UPDATE_URL = `${BASE_URL}/admin/users`;
const USER_DELETE_URL = `${BASE_URL}/admin/users`;
const USER_GET_URL = `${BASE_URL}/admin/users`;
const USER_GET_USER_PROFILE = `${BASE_URL}/user/profile`;

// RENAME FILES

const AUDIO_NAME_UPDATE = `${BASE_URL}/audio/update-file-name`;

// INITIAL IMAGE
const IMAGE_ASSETS_URL = `${BASE_URL}/initial-image/assets`;
const IMAGE_ASSETS_S_URL = `${BASE_URL}/initial-image/asset_single`;
const IMAGE_SIGNED_URL = `${BASE_URL}/initial-image/upload`;
const IMAGE_SIGNED_URL_RESTYLE = `${BASE_URL}/restyle-image/upload`;
const DELETE_IMAGE = `${BASE_URL}/image`;
const DELETE_EXTERNAL_VIDEO = `${BASE_URL}/video/external`;
const DELETE_IMAGES = `${BASE_URL}/images`;
const PROMPT_INITIAL_IMAGE = `${BASE_URL}/predictions`;
const IMAGE_REPLICATE = `${BASE_URL}/generate_prompt_with_replicate`;

//RESTYLE VIDEOS
const VIDEO_EXTERNAL_URL = `${BASE_URL}/external-video/upload`;
const VIDEO_REQUEST_RESTYLE_URL = `${BASE_URL}/video/restyle`;

// NEW
const NEW_SIGNED_URL_URL = `${BASE_URL}/files/uploadurl`;
const DREAMBOOTH_UPLOAD_URL = `${BASE_URL}/dreambooth/uploadurl`;
const DREAMBOOTH_UPLOADED_URL = `${BASE_URL}/dreambooth/uploaded`;
const AUDIO_INGEST_UPLOAD_URL = `${BASE_URL}/audio/uploadurl`;
const CUT_AUDIO_AND_INGEST_URL = `${BASE_URL}/audio/upload`;
const AVATAR_DELETE = `${BASE_URL}/avatar`;
const PROJECT_DELETE = `${BASE_URL}/project`;
const RESTYLE_DELETE = `${BASE_URL}/restyled-delete`;

// ACCOUNT TYPES
const ACCOUNT_TYPES_URL = `${BASE_URL}/account_types/`;
const IN_GENERATION_COUNT_URL = `${BASE_URL}/videos/in_simultaneous`;

// JWT AUTHENTICATION

const AUTHENTICATION_USER = `${BASE_URL}/custom-token`;

interface CustomTokenPayload extends JwtPayload {
  account_type: string;
  subscription_status: string;
  email: string;
}

export class APIClient {
  _auth_header: any = { access_token: "abc123" };
  url = "";

  constructor(url: string = BASE_URL) {
    this.url = url;
  }

  //PAUSE
  async pause_media(owner_id: string, video_id: string, execution_arn: string) {
    try {
      const url = STOP_EXECUTION;
      const data = {
        owner_id: owner_id,
        video_id: video_id,
        execution_arn: execution_arn,
      };
      const headers = this._auth_header;
      const res = await axios.post(url, data, {
        headers: headers,
      });
      return res.data.message;
    } catch (err) {
      return null;
    }
  }

  async authenticate_user(email: string, password: string) {
    try {
      const url = AUTHENTICATION_USER;
      const data = {
        email: email,
        password: password,
      };
      const headers = this._auth_header;
      const res = await axios.post(url, data, {
        headers: headers,
      });

      const { custom_token } = res.data;
      let accountType: string | undefined;
      let subscriptionStatus: string | undefined;
      let userEmail: string | undefined;

      if (typeof custom_token === "string") {
        localStorage.setItem("custom_token", custom_token);

        const decodedToken = jwtDecode<CustomTokenPayload>(custom_token);
        accountType = decodedToken.account_type;
        subscriptionStatus = decodedToken.subscription_status;
        userEmail = decodedToken.email;
      } else {
        console.error("custom_token is not a string:", custom_token);
      }
      return { custom_token, accountType, subscriptionStatus, userEmail };
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // ================== LOGIN ==================
  authenticate(username?: string, password?: string, apiKey?: string) {
    if (apiKey) {
      this._auth_header = { access_token: apiKey };
    }
    if (username && password) {
      const data = makeData({ password, username });
      const config = makeConfig({ method: POST, url: AUTH_URL, data });
      const okFunc = (r: any) => {
        console.log(r.data["access_token"]);
        this._auth_header = {
          Authorization: `Bearer ${r.data["access_token"]}`,
        };
      };
      const errFunc = (e: any) => console.log(e);
      makeRequest({ config, errFunc, okFunc });
    }
  }

  // ================== VIDEO ==================
  get_video_metadata(
    owner_id: string,
    video_id?: string,
    okFunc = console.log,
    errFunc = console.error
  ) {
    const url = video_id
      ? `${VIDEO_META_L_URL}/${owner_id}/${video_id}`
      : `${VIDEO_META_URL}/${owner_id}`;

    const config = makeConfig({ headers: this._auth_header, method: GET, url });
    makeRequest({ config, errFunc, okFunc });
  }

  getVideoGenerationParamsTemplate(videoId: string) {
    const res = libraryJson.template;
    const findVideo = res.find((x) => x.video_id == videoId);

    if (findVideo) {
      const settings = findVideo.video_settings;
      const {
        style,
        animation,
        audio,
        transitions,
        audio_reactive_config,
        strength_prompt_compensation,
      } = settings;

      const initialImageKey = animation.initial_image_key?.split("/").pop();

      let states = {
        generateVideo: {
          videoConfig: {
            steps: animation.steps || 26,
            strength: animation.strength || 0.7,
            cadence: animation.diffusion_cadence || 3,
            fps: animation.fps || 24,
            seed: animation.seed || 0,
            scale: 35,
          },
          transitions,
          strength_prompt_compensation: {
            enable: strength_prompt_compensation.enable || true,
            duration: strength_prompt_compensation.duration || 0.5,
            strength: strength_prompt_compensation.strength || 0.2,
          },
          aspectRatio: style.orientation || "Square",
          animationMode: animation.animation_mode || "2D",
          boomerang: false,
          zoomPulse: audio_reactive_config.enable_zoom_pulse_for_drums,
          vocalShake: audio_reactive_config.enable_vocal_shake,
        },
        audioAsset: {
          isFromLibrary: true,
          selected: audio.audio_asset_id || "",
          start: audio.start_time,
          end: audio.stop_time,
        },
        startingFrame: {
          videoName: settings.video_name,
          initialImageKey,
        },
        subjectsMatter: {
          subjectMatter: style.subject_matter,
        },
        artStyles: {
          themeId: style.theme_id,
          userAvatar: style.dreambooth_id,
        },
      };

      return states;
    }
  }

  async getVideoGenerationParams(videoId: string, ownerId: string) {
    try {
      const res = await axios.get(`${VIDEO_META_L_URL}/${ownerId}/${videoId}`, {
        headers: { ...this._auth_header },
      });
      if (res.data && res.data.result) {
        const [settings] = res.data.result;
        const {
          video_settings: {
            style,
            animation,
            audio,
            transitions,
            audio_reactive_config,
            spotify,
          },
        } = settings;

        const initialImageKey = animation.initial_image_key?.split("/").pop();

        let states = {
          generateVideo: {
            videoConfig: {
              duration: animation.duration || 4,
              steps: animation.steps || 26,
              strength: animation.strength || 0.7,
              cadence: animation.diffusion_cadence || 3,
              fps: animation.fps || 24,
              seed: animation.seed || 0,
              scale: 35,
            },
            transitions,

            aspectRatio: style.orientation || "Square",
            animationMode: animation.animation_mode || "2D",
            boomerang: false,
            motionStrength:
              audio_reactive_config.constant_motion_strength || 0.5,
            zoomPulse: audio_reactive_config.enable_zoom_pulse_for_drums,
            vocalShake: audio_reactive_config.enable_vocal_shake,
          },
          audioAsset: {
            isFromLibrary: true,
            selected: audio.audio_asset_id || "",
            start: audio.start_time,
            end: audio.stop_time,
          },
          startingFrame: {
            videoName: settings.video_settings.video_name,
            initialImageKey,
            spotify,
          },
          subjectsMatter: {
            subjectMatter: style.subject_matter,
            negativePrompt: style.negative_prompts_choosen,
            time: style.subject_matter_time,
          },
          artStyles: {
            themeId: style.theme_id,
            userAvatar: style.dreambooth_id,
          },
        };

        return states;
      }
    } catch (err) {
      console.error(err);
      return {};
    }
  }

  get_video_allowed_orientations() {
    const url = VIDEO_ORIENTATIONS_URL;
    const config = makeConfig({ headers: this._auth_header, method: GET, url });
    const okFunc = (r: any) => console.log(r.data);
    const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  get_video_prompts(
    owner_id: string,
    video_id: string,
    okFunc: Function,
    errFunc: Function
  ) {
    const url = `${VIDEO_PROMPTS_URL}/${owner_id}/${video_id}`;
    const config = makeConfig({ headers: this._auth_header, method: GET, url });
    makeRequest({ config, okFunc, errFunc });
  }

  get_user_limits(owner_id: string, okFunc: Function, errFunc: Function) {
    const url = `${VIDEO_LIMITS_URL}`;
    const headers = this._auth_header;
    const params = { owner_id };
    const method = GET;
    const config = makeConfig({ headers, method, url, params });
    makeRequest({ config, errFunc, okFunc });
  }

  get_video_build_params(owner_id: string, video_id: string) {
    const url = `${VIDEO_PARAMS_URL}/${owner_id}/${video_id}`;
    const config = makeConfig({ headers: this._auth_header, method: GET, url });
    const okFunc = (r: any) => console.log(r.data);
    const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  get_video_keyframes(owner_id: string, video_id: string) {
    const url = `${VIDEO_KEYFRAME_URL}/${owner_id}/${video_id}`;
    const config = makeConfig({ headers: this._auth_header, method: GET, url });
    const okFunc = (r: any) => console.log(r.data);
    const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  get_ui_supported_transitions(animation_type: string) {
    const url = `${VIDEO_TRANSITIONS_URL}`;
    const headers = this._auth_header;
    const method = GET;
    const params = { animation_type };
    const config = makeConfig({ headers, method, url, params });
    const okFunc = (r: any) => console.log(r.data);
    const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  generate_video(
    owner_id: string,
    body: VideoRequest,
    okFunc = console.log,
    errFunc = console.error
  ) {
    // trouble function on muze_api_client.py
    const url = `${VIDEO_GENERATE_URL}`;
    const headers = this._auth_header;
    const method = POST;
    const params = { owner_id };
    const data = makeData(body);
    const config = makeConfig({ headers, method, url, data, params });

    makeRequest({ config, errFunc, okFunc });
  }

  generate_video_permission(owner_id: string, body?: Object) {
    const b = body ? body : { style: {}, animation: {}, audio: {}, build: {} };
    const url = `${VIDEO_PERMISSION_URL}`;
    const headers = this._auth_header;
    const method = POST;
    const params = { owner_id };
    const data = makeData(b);
    const config = makeConfig({ headers, method, url, data, params });
    const okFunc = (r: any) => console.log(r.data);
    const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  //RESTYLE
  generate_restyle_video(
    owner_id: string,
    video_name: string,
    is_watermark: string,
    positivePrompt: string = "{subject_matter}, (highly detailed, ultra realistic, 8K):1.3",
    subjectMatter: string,
    outputType: string,
    frameWidth: number,
    frameHeight: number,
    scalingFactor: number = 1.5,
    ipadapterReferenceimage: string = "",
    ipadapterScale: number = 0.7,
    useIpadapter: string = "True"
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestBody = new RestyleRequest(
        owner_id,
        video_name,
        is_watermark,
        positivePrompt,
        subjectMatter,
        outputType,
        frameWidth,
        frameHeight,
        scalingFactor,
        ipadapterReferenceimage,
        ipadapterScale,
        useIpadapter
      );

      const url = `${VIDEO_REQUEST_RESTYLE_URL}?owner_id=${encodeURIComponent(
        owner_id
      )}&video_name=${encodeURIComponent(
        video_name
      )}&is_watermark=${is_watermark}`;
      const headers = this._auth_header;
      const method = "POST";
      const data = {
        single_stage_settings: requestBody.single_stage_settings,
        two_stage_settings: requestBody.two_stage_settings,
      };

      const config = {
        method: method,
        url: url,
        headers: headers,
        data: data,
      };

      axios(config)
        .then((response) => {
          if (response.data.statusCode === 200) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  generate_upscale_video(
    owner_id: string,
    video_asset_id: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestBody = new UpscalingSettings(owner_id, video_asset_id);

      const url = `${VIDEO_UPSCALE_URL}?owner_id=${encodeURIComponent(
        requestBody.owner_id
      )}&video_asset_id=${requestBody.video_asset_id}`;
      const headers = this._auth_header;
      const method = POST;
      const data = makeData(requestBody);
      const config = makeConfig({ headers, method, url, data });
      const okFunc = (r: any) => {
        console.log(r.data);
        resolve();
      };
      const errFunc = (e: any) => {
        console.log(e);
        reject(e);
      };
      makeRequest({ config, errFunc, okFunc });
    });
  }

  generate_upscale_restyle(
    owner_id: string,
    video_asset_id: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestBody = new UpscalingSettings(owner_id, video_asset_id);
      const url = `${VIDEO_UPSCALE_URL_RESTYLE}?owner_id=${encodeURIComponent(
        requestBody.owner_id
      )}&video_id=${requestBody.video_asset_id}`;
      const headers = this._auth_header;
      const method = POST;
      const data = makeData(requestBody);
      const config = makeConfig({ headers, method, url, data });
      const okFunc = (r: any) => {
        console.log(r.data);
        resolve();
      };
      const errFunc = (e: any) => {
        console.log(e);
        reject(e);
      };
      makeRequest({ config, errFunc, okFunc });
    });
  }

  get_upscale_video(owner_id: string, video_asset_id: string): any {
    const url = `${VIDEO_UPSCALE_ASSETS}/${owner_id}/${video_asset_id}`;
    const headers = this._auth_header;
    try {
      return axios.get(url, { headers });
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  get_plan_subscription_stripe(owner_id: string): any {
    const url = `${PLAN_SUBSCRIPTION_INFO_STRIPE}/${owner_id}`;
    const headers = {
      ...this._auth_header,
    };

    try {
      return axios.get(url, { headers });
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  get_plan_subscription(owner_id: string): any {
    const url = `${PLAN_SUBSCRIPTION_INFO}/${owner_id}`;

    const headers = {
      ...this._auth_header,
    };

    try {
      return axios.get(url, { headers });
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      return "";
    }
  }

  get_prompt_initialImage(image_url: string): any {
    const url = `${PROMPT_INITIAL_IMAGE}/?image_url=${image_url}`;
    const headers = {
      ...this._auth_header,
      "Content-Type": "application/json",
    };
    try {
      return axios.post(url, { headers });
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      return "";
    }
  }

  get_image_replicate(owner_id: string, requestData: any): any {
    const url = `${IMAGE_REPLICATE}/${owner_id}`;
    const headers = {
      ...this._auth_header,
      "Content-Type": "application/json",
    };

    try {
      return axios.post(url, requestData, { headers });
    } catch (error) {
      console.error("Error in get_image_replicate:", error);
      return "";
    }
  }

  async paddle_upgrade_subscription(
    subscriptionId: string,
    items: SubscriptionItem[]
  ): Promise<any> {
    const url = `${PLAN_SUBSCRIPTION_UPGRADE}/${subscriptionId}`;
    const headers = {
      ...this._auth_header,
      "Content-Type": "application/json",
    };

    const body = {
      items,
    };

    return axios
      .post(url, body, { headers })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  async paddle_cancel_subscription(subscriptionId: string): Promise<any> {
    const url = `${CANCEL_PLAN}/${subscriptionId}`;
    const headers = {
      ...this._auth_header,
      "Content-Type": "application/json",
    };
    return axios
      .post(url, { headers })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  async paddle_downgrade_subscription(
    subscriptionId: string,
    items: SubscriptionItem[]
  ): Promise<any> {
    const url = `${PLAN_SUBSCRIPTION_DOWNGRADE}/${subscriptionId}`;
    const headers = {
      ...this._auth_header,
      "Content-Type": "application/json",
    };

    const body = {
      items,
    };

    return axios
      .post(url, body, { headers })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  async paddle_change_subscription_trialing(
    subscriptionId: string,
    items: SubscriptionItem[]
  ): Promise<any> {
    const url = `${CHANGE_SUBSCRIPTION_TRIALING}/${subscriptionId}`;
    const headers = {
      ...this._auth_header,
      "Content-Type": "application/json",
    };

    const body = {
      items,
    };

    return axios
      .post(url, body, { headers })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  video_delete(owner_id: string, video_ids: string[]) {
    const url = `${VIDEO_DELETE_URL}`;
    const headers = this._auth_header;
    const method = POST;
    const params = { owner_id };
    const data = makeData(video_ids);
    const config = makeConfig({ headers, method, url, data, params });
    const okFunc = (r: any) => console.log(r.data);
    const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  // ================== STYLE ==================
  get_video_styles(public_only?: boolean) {
    const url = `${STYLES_URL}`;
    const headers = this._auth_header;
    const method = GET;
    const params = { public_only };
    const config = makeConfig({ headers, method, url, params });
    const okFunc = (r: any) => console.log(r.data);
    const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  generate_thumbnails_for_styles(okFunc: Function, errFunc: Function) {
    const url = `${STYLES_GENERATE_URL}`;
    const headers = this._auth_header;
    const method = POST;
    const config = makeConfig({ headers, method, url });

    makeRequest({ config, errFunc, okFunc });
  }

  async get_settings_for_styles(okFunc: Function, errFunc: Function) {
    const url = SETTINGS_STYLES;
    const headers = this._auth_header;
    const method = GET;
    const config = makeConfig({ headers, method, url });

    makeRequest({ config, errFunc, okFunc });

    try {
      const url = SETTINGS_STYLES;
      const headers = this._auth_header;
      const res = await axios.get(url, {
        headers,
      });
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  // ================== AUDIO ==================
  get_audio_assets(
    owner_id: string,
    okFunc: Function,
    errFunc: Function,
    audio_assets_id?: string
  ) {
    const url = audio_assets_id
      ? `${AUDIO_ASSETS_S_URL}/${audio_assets_id}`
      : AUDIO_ASSETS_URL;
    const headers = this._auth_header;
    const method = GET;
    const params = { owner_id };
    const config = makeConfig({ headers, method, url, params });
    // const okFunc = (r: any) => console.log(r.data);
    // const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  get_audio_segmentation(owner_id: string, audio_asset_id: string) {
    const url = `${AUDIO_SEGMENTATION_URL}/${owner_id}/${audio_asset_id}`;
    const headers = this._auth_header;
    const method = GET;
    const config = makeConfig({ headers, method, url });
    const okFunc = (r: any) => console.log(r.data);
    const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  get_audio_stems_analysis(owner_id: string, audio_asset_id: string) {
    const url = `${AUDIO_ANALISYS_URL}/${owner_id}/${audio_asset_id}`;
    const headers = this._auth_header;
    const method = GET;
    const config = makeConfig({ headers, method, url });
    const okFunc = (r: any) => console.log(r.data);
    const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  generate_audio_stem_camera_mappings(
    owner_id: string,
    args: AudioGenerationParams,
    audio_asset_id?: string
  ) {
    const data = makeData({
      ...args,
      max_concurrent_mappings: args.max_concurrent_mappings
        ? args.max_concurrent_mappings
        : 3,
      motion_frac: args.motion_frac ? args.motion_frac : 0.2,
      time_between_static_transitions: args.time_between_static_transitions
        ? args.time_between_static_transitions
        : 6.0,
    });
    const url = `${AUDIO_GENERATE_URL}`;
    const headers = this._auth_header;
    const method = POST;
    const params = { owner_id, audio_asset_id };
    const config = makeConfig({ headers, method, url, data, params });
    const okFunc = (r: any) => console.log(r.data);
    const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  async delete_audio_resource(
    owner_id: string,
    audio_asset_id?: string,
    okFunc = console.log,
    errFunc = console.error
  ) {
    try {
      const url = `${AUDIO_DELETE_URL}/${owner_id}/${audio_asset_id}`;
      const headers = this._auth_header;
      const method = DELETE;
      const config = makeConfig({ headers, method, url });

      await makeRequest({ config, errFunc, okFunc });

      okFunc();
    } catch (error) {
      Sentry.captureException(error);
      Sentry.setUser({ email: owner_id });
      errFunc(error);
    }
  }

  list_buble_uploads(okFunc: Function, errFunc: Function) {
    const url = `${AUDIO_LIST_URL}`;
    const headers = this._auth_header;
    const method = GET;
    const config = makeConfig({ headers, method, url });
    // const okFunc = (r: any) => console.log(r.results);
    // const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  ingest_audio(owner_id: string, audio: AudioUploadParams) {
    const url = `${AUDIO_INGEST_URL}`;
    const headers = { ...this._auth_header, accept: "application/json" };
    const method = POST;
    const data = makeData(audio);
    const params = { owner_id };
    const config = makeConfig({ headers, method, url, data, params });
    const okFunc = (r: any) => console.log(r.data);
    const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  get_audio_transcription(
    owner_id: string,
    asset_id: string,
    aligned?: boolean
  ) {
    let url = aligned ? AUDIO_TRANSC_ALIGN_URL : AUDIO_TRANSCRIPT_URL;
    url = `${url}?owner_id=${owner_id}&asset_id=${asset_id}`;
    const headers = this._auth_header;
    const method = GET;
    const data = makeData({ owner_id, asset_id });
    const config = makeConfig({ headers, method, url, data });
    const okFunc = (r: any) => console.log(r.data);
    const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  // ================== DREAMBOOTH ==================
  dreambooth_train(
    owner_id: string,
    train_params: DTrainParams,
    okFunc: Function | undefined = console.log,
    errFunc: Function | undefined = console.error
  ) {
    const url = `${DREAMBOOTH_TRAIN_URL}`;
    const headers = this._auth_header;
    const method = POST;
    const data = makeData(train_params);
    const params = { owner_id };
    const config = makeConfig({ headers, method, url, data, params });
    makeRequest({ config, errFunc, okFunc });
  }

  dreambooth_get_list_of_models(owner_id: string) {
    const url = `${DREAMB_LIST_MODELS_URL}/${owner_id}`;
    const headers = this._auth_header;
    const method = GET;
    const config = makeConfig({ headers, method, url });
    const okFunc = (r: any) =>
      console.log("dreambooth_get_list_of_models: ", r);
    const errFunc = (e: any) =>
      console.log("dreambooth_get_list_of_models error: ", e);
    makeRequest({ config, errFunc, okFunc });
  }

  dreambooth_delete(owner_id: string, dreambooth_id_list: string[]) {
    const url = `${DREAMBOOTH_DELETE_URL}`;
    const headers = this._auth_header;
    const method = POST;
    const params = { owner_id };
    const data = makeData(dreambooth_id_list);
    const config = makeConfig({ headers, method, url, data, params });
    const okFunc = (r: any) => console.log(r.data);
    const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  dreambooth_get_preview_images(owner_id: string, dreambooth_id: string) {
    const url = `${DREAMBOOTH_PREVIEW_URL}/${owner_id}/${dreambooth_id}`;
    const headers = this._auth_header;
    try {
      return axios.get(url, { headers });
    } catch (error) {
      Sentry.captureException(error);
      Sentry.setUser({ email: owner_id });
      console.error(error);
      return "";
    }
  }

  dreambooth_get_preview_images_default(
    owner_default: string,
    dreambooth_id: string
  ) {
    const url = `${DREAMBOOTH_PREVIEW_DEFAULT_URL}/${owner_default}/${dreambooth_id}`;
    const headers = this._auth_header;
    try {
      return axios.get(url, { headers });
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  async styles_settings_custom(owner_id: string): Promise<any> {
    const url = `${BASE_URL}/styles/settings/${owner_id}`;

    try {
      const response = await axios.get(url, { headers: this._auth_header });
      return response.data;
    } catch (error) {
      console.error("Error fetching styles settings:", error);
      throw error;
    }
  }

  avatar_delete(
    owner_id: string,
    avatar_id: string,
    okFunc = console.log,
    errFunc = console.error
  ) {
    const url = `${AVATAR_DELETE}/${owner_id}/${avatar_id}`;
    const headers = this._auth_header;
    const method = DELETE;
    const config = makeConfig({ headers, method, url });
    makeRequest({ config, errFunc, okFunc });
  }

  project_delete(
    owner_id: string,
    project_id: string,
    okFunc = console.log,
    errFunc = console.error
  ) {
    const url = `${PROJECT_DELETE}/${owner_id}/${project_id}`;
    const headers = this._auth_header;
    const method = DELETE;
    const config = makeConfig({ headers, method, url });
    makeRequest({ config, errFunc, okFunc });
  }

  restyle_delete(
    owner_id: string,
    project_id: string,
    okFunc = console.log,
    errFunc = console.error
  ) {
    const url = `${RESTYLE_DELETE}/${owner_id}/${project_id}`;
    const headers = this._auth_header;
    const method = DELETE;
    const config = makeConfig({ headers, method, url });
    makeRequest({ config, errFunc, okFunc });
  }

  // ================== USER ==================
  // register_new_user(owner: RegisterNewUser, callback: any = () => {}) {
  //   const url = `${USER_CREATE_URL}`;
  //   const headers = this._auth_header;
  //   const method = POST;
  //   const data = makeData(owner);
  //   const config = makeConfig({ headers, method, url, data });
  //   const okFunc = callback;
  //   const errFunc = (e: any) => console.log(e);
  //   makeRequest({ config, errFunc, okFunc });
  // }

  // async register_new_user(owner: RegisterNewUser, callback: any = () => {}) {
  //   try {
  //     const url = `${USER_CREATE_URL}`;
  //     const data = makeData(owner);
  //     const method = POST;
  //     const headers = this._auth_header;
  //     const config = makeConfig({ headers, method, url, data });
  //     const res = await axios.request(config as any);
  //     return res.data;
  //   } catch (err) {
  //     console.error(err);
  //     return null;
  //   }
  // }

  async register_new_user(email: string) {
    try {
      // const custom_token = localStorage.getItem("custom_token");

      // if (typeof custom_token !== "string") {
      //   console.error("Invalid custom_token:", custom_token);
      //   return null;
      // }

      const res = await axios.get(`${REGISTER_CUSTOMER_CREDITS_URL}/${email}`, {
        headers: { ...this._auth_header },
      });
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async register_new_user_stripe(email: string, name?: string) {
    try {
      // Construir a URL com o parâmetro name, se fornecido
      const url = new URL(`${REGISTER_CUSTOMER_CREDITS_URL_STRIPE}/${email}`);
      if (name) {
        url.searchParams.append("name", name);
      }

      // Fazer a requisição GET
      const res = await axios.get(url.toString(), {
        headers: { ...this._auth_header },
      });

      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  create_new_user(
    owner: NewUserPayload,
    okFunc = console.log,
    errFunc = console.error
  ) {
    const url = `${USER_CREATE_URL}`;
    const headers = this._auth_header;
    const method = POST;
    const data = makeData(owner);
    const config = makeConfig({ headers, method, url, data });
    makeRequest({ config, errFunc, okFunc });
  }

  delete_user(user_id: string, okFunc = console.log, errFunc = console.error) {
    const url = `${USER_DELETE_URL}/${user_id}`;
    const headers = this._auth_header;
    const method = DELETE;
    const config = makeConfig({ headers, method, url });
    makeRequest({ config, errFunc, okFunc });
  }

  async getUserByEmail(email: string) {
    try {
      const res = await axios.get(`${USER_GET_URL}/${email}`, {
        headers: { ...this._auth_header },
      });
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async getPlanByEmail(email: string) {
    try {
      const res = await axios.get(`${GET_USER_PLAN}/${email}`, {
        headers: { ...this._auth_header },
      });
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async getUserData(userId: string) {
    try {
      const res = await axios.get(`${USER_GET_USER_PROFILE}/${userId}`, {
        headers: { ...this._auth_header },
      });
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async update_user(user_id: string, user_info: UpdateUserDetails) {
    try {
      const res = await axios.put(`${USER_UPDATE_URL}/${user_id}`, user_info, {
        headers: { ...this._auth_header },
      });
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async update_music_name(audio_asset_id: string, request: AudioUpdateRequest) {
    try {
      const res = await axios.put(
        `${AUDIO_NAME_UPDATE}/${audio_asset_id}`,
        request,
        {
          headers: { ...this._auth_header },
        }
      );
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  // ========= SIGNED URL FOR UPLOAD ==========

  get_signed_url(
    owner_id: string,
    fileKey: string,
    fileType: string,
    okFunc: Function,
    errFunc = console.error
  ) {
    const url = `${NEW_SIGNED_URL_URL}/${owner_id}/${fileKey}/${fileType}`;
    const headers = { ...this._auth_header, accept: "application/json" };
    const method = GET;
    const config = makeConfig({ headers, method, url });
    makeRequest({ config, errFunc, okFunc });
  }

  get_dreambooth_signed_url(
    owner_id: string,
    filename: string,
    okFunc = console.log,
    errFunc = console.error
  ) {
    const url = `${DREAMBOOTH_UPLOAD_URL}/${owner_id}/${filename}`;
    const headers = { ...this._auth_header, accept: "application/json" };
    const method = GET;
    const config = makeConfig({ headers, method, url });
    makeRequest({ config, errFunc, okFunc });
  }

  get_dreambooth_temp_signed_url(owner_id: string, filename: string) {
    const url = `${DREAMBOOTH_UPLOAD_URL}/${owner_id}/${filename}`;
    const headers = { ...this._auth_header, accept: "application/json" };

    try {
      return axios.get(url, { headers });
    } catch (error) {
      Sentry.captureException(error);
      Sentry.setUser({ email: owner_id });
      console.error(error);
      return "";
    }
  }

  get_audio_for_ingest_signed_url(
    owner_id: string,
    filename: string,
    okFunc = console.log,
    errFunc = console.error
  ) {
    const url = `${AUDIO_INGEST_UPLOAD_URL}/${owner_id}/${filename}`;
    const headers = { ...this._auth_header, accept: "application/json" };
    const method = GET;
    const config = makeConfig({ headers, method, url });
    makeRequest({ config, errFunc, okFunc });
  }

  list_dreambooth_uploaded(
    owner_id: string,
    okFunc = console.log,
    errFunc = console.error
  ) {
    const url = `${DREAMBOOTH_UPLOADED_URL}/${owner_id}`;
    const headers = { ...this._auth_header, accept: "application/json" };
    const method = GET;
    const config = makeConfig({ headers, method, url });
    makeRequest({ config, errFunc, okFunc });
  }

  // ========= INITIAL IMAGES ==========

  get_image_assets(owner_id: string, image_asset_id?: string | boolean) {
    const url = image_asset_id
      ? `${DREAMBOOTH_UPLOADED_URL}/${owner_id}`
      : DREAMBOOTH_UPLOADED_URL;
    const headers = this._auth_header;
    const method = GET;
    const config = makeConfig({ headers, method, url });
    const okFunc = (r: any) => console.log(r.data);
    const errFunc = (e: any) => console.log(e);
    makeRequest({ config, errFunc, okFunc });
  }

  upload_image_asset(ownerId: string, file: File, callback: Function) {
    const url = `${IMAGE_SIGNED_URL}/${ownerId}/${file.name}`;
    const headers = { ...this._auth_header, accept: "application/json" };
    const method = GET;
    const config = makeConfig({ headers, url, method });
    const errFunc = console.error;
    const okFunc = (res: any) => {
      if (res.includes("https://")) {
        axios
          .put(res, file)
          .then((response) => {
            callback(response);
          })
          .catch((error) => {
            console.error("something goes wrong: ", error);
          });
      } else {
        console.error("something goes wrong: ", res);
      }
    };
    makeRequest({ config, errFunc, okFunc }); // ask for an signed url
  }

  upload_image_asset_restyle(ownerId: string, file: File, callback: Function) {
    console.log("caiu em upload image asset restyle");
    const url = `${IMAGE_SIGNED_URL_RESTYLE}/${ownerId}/${file.name}`;
    const headers = { ...this._auth_header, accept: "application/json" };
    const method = GET;
    const config = makeConfig({ headers, url, method });
    const errFunc = console.error;
    const okFunc = (res: any) => {
      if (res.includes("https://")) {
        axios
          .put(res, file)
          .then((response) => {
            callback(response);
          })
          .catch((error) => {
            console.error("something goes wrong: ", error);
          });
      } else {
        console.error("something goes wrong: ", res);
      }
    };
    makeRequest({ config, errFunc, okFunc });
  }

  //VID2VID//
  upload_external_video(
    ownerId: string,
    file: File,
    width: number,
    height: number,
    callback: Function,
    errFunc: Function,
    onProgress: (progressEvent: AxiosProgressEvent) => void
  ) {
    const MAX_DURATION = 60;
    const video = document.createElement("video");
    video.preload = "metadata";

    const fileURL = URL.createObjectURL(file);
    video.src = fileURL;

    video.onloadedmetadata = () => {
      URL.revokeObjectURL(fileURL);

      if (video.duration > MAX_DURATION) {
        toast.error("Video duration exceeds the 60 seconds limit", {
          duration: 5000,
        });
        errFunc(new Error("Video duration exceeds the 60 seconds limit"));
        return;
      }

      const url = `${VIDEO_EXTERNAL_URL}/${ownerId}/${file.name}?width=${width}&height=${height}`;

      const headers = {
        ...this._auth_header,
        accept: "application/json",
      };

      const method = GET;
      const config = makeConfig({ headers, url, method });

      const okFunc = (res: any) => {
        if (res.url && res.url.includes("https://")) {
          axios
            .put(res.url, file, {
              headers: {
                "x-amz-meta-height": height.toString(),
                "x-amz-meta-width": width.toString(),
              },
              onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                if (progressEvent.total) {
                  onProgress(progressEvent);
                }
              },
            })
            .then((response) => {
              callback(response);
            })
            .catch((error) => {
              console.error("something goes wrong: ", error);
              errFunc(error);
            });
        } else {
          console.error("something goes wrong: ", res);
          errFunc(new Error("Invalid response URL"));
        }
      };

      makeRequest({ config, errFunc, okFunc });
    };
  }

  delete_initial_image(
    owner_id: string,
    key: string,
    okFunc = console.log,
    errFunc = console.error
  ) {
    const url = `${DELETE_IMAGE}/${owner_id}/?key=${key}`;
    const headers = this._auth_header;
    const method = DELETE;
    const config = makeConfig({ headers, method, url });
    makeRequest({ config, errFunc, okFunc });
  }

  delete_external_video(
    owner_id: string,
    key: string,
    okFunc = console.log,
    errFunc = console.log
  ) {
    const url = `${DELETE_EXTERNAL_VIDEO}/${owner_id}/?key=${key}`;
    const headers = this._auth_header;
    const method = DELETE;
    const config = makeConfig({ headers, method, url });
    makeRequest({ config, errFunc, okFunc });
  }

  delete_initial_images(
    owner_id: string,
    keys: string[],
    okFunc = console.log,
    errFunc = console.error
  ) {
    const url = `${DELETE_IMAGES}/${owner_id}`;
    const headers = this._auth_header;
    const method = "DELETE";
    const params = { owner_id };
    const data = makeData(keys);
    const config = makeConfig({ method, url, data, headers, params });
    makeRequest({ config, errFunc, okFunc });
  }

  // ==================== AUDIO CROP ====================
  cut_mp3({
    ownerId,
    file,
    start,
    end,
    okFunc = console.log,
    errFunc = console.error,
  }: {
    ownerId: string;
    file: File;
    start: number;
    end: number;
    okFunc?: Function;
    errFunc?: Function;
  }) {
    const url = `${CUT_AUDIO_AND_INGEST_URL}/${ownerId}/?start=${start}&end=${end}`;
    const headers = {
      ...this._auth_header,
      "Content-Type": "multipart/form-data",
    };
    const method = POST;
    const data = new FormData();
    data.append("audio_file", file, file.name);
    const config = makeConfig({ headers, method, url, data });
    makeRequest({ config, errFunc, okFunc });
  }

  // ==================== ACCOUNT TYPES ====================
  async getAccount(accountName: string) {
    try {
      const res = await axios.get(`${ACCOUNT_TYPES_URL}/${accountName}`, {
        headers: { ...this._auth_header },
      });
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
  async createAccount(newAccount: AccountTypes) {
    try {
      const res = await axios.post(`${ACCOUNT_TYPES_URL}`, newAccount, {
        headers: { ...this._auth_header },
      });
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
  async updateAccount(accountName: string, newAccount: AccountTypes) {
    try {
      const res = await axios.put(
        `${ACCOUNT_TYPES_URL}/${accountName}`,
        newAccount,
        { headers: { ...this._auth_header } }
      );
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
  async deleteAccount(accountName: string) {
    try {
      const res = await axios.delete(`${ACCOUNT_TYPES_URL}/${accountName}`, {
        headers: { ...this._auth_header },
      });
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  // ==================== SIMULTANEOUS GENERATION STATUS ====================
  async getInGenerationCount(ownerId: string) {
    try {
      const res = await axios.get(`${IN_GENERATION_COUNT_URL}/${ownerId}`, {
        headers: { ...this._auth_header },
      });
      return res.data.payload;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
}

export function upload_file(
  url: string,
  data: any,
  okFunc = console.log,
  errFunc = console.error
) {
  const headers = { "Content-Type": "audio/mpeg" };
  // const headers = { 'Content-Type': 'image/jpeg' };
  const method = PUT;
  const config = makeConfig({ headers, method, url, data });
  makeRequest({ config, errFunc, okFunc });
}

// ================== UTILS ==================
interface RegisterNewUser {
  owner_id: string;
  // name: string;
  // email: string;
  // subscription_tier: string;
  // credits: number;
  // phone: string;
  // password: string;
  // userId: string;
}
interface NewUserPayload {
  owner_id: string;
  name: string;
  email: string;
  subscription_tier: string;
  credits: number;
  userId: string;
}
export interface DTrainParams {
  name: string;
  files: string[];
  train: {
    max_train_steps: number;
    num_class_images: number;
    instance_token: string;
    instance_class: string;
  };
}

interface AudioGenerationParams {
  static_transitions: boolean;
  segment_transitions: boolean;
  enable_constant_motion_transitions: boolean;
  motion_frac: number;
  max_concurrent_mappings: number;
  time_between_static_transitions: number;
  animation_mode: string;
  segmentation_source?: string;
  allowed_camera_motions?: string;
}

export interface AudioUploadParams {
  name: string;
  key: string;
  delete_source: boolean;
  next_step_video: boolean;
}

interface ReqParms {
  config: any;
  okFunc: Function;
  errFunc: Function;
}

interface Config {
  method: string;
  url: string;
  data?: string | FormData;
  headers?: Object;
  params?: any;
}

interface AccountTypes {
  name: string;
  audioLimit: number;
  mediaLimits: number;
  simultaneousVideos: number;
  vid2vidLimits: number;
  gpuLimit: number;
  simultaneousVid2Vid_Limits: number;
  monthly_price_id: string;
  annual_price_id: string;
  one_time_price_id: number;
}

const makeData = (obj: Object) => JSON.stringify(obj);

const makeConfig = ({ method, url, data, headers, params }: Config) => ({
  data,
  method,
  url,
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
    "User-Agent": "PostmanRuntime/7.32.3",
    ...headers,
  },
  params,
});

const makeRequest = ({ config, okFunc, errFunc }: ReqParms) =>
  axios
    .request(config)
    .then((r) => {
      okFunc(r.data);
    })
    .catch((e) => {
      Sentry.captureException(e);
      errFunc(e.data);
    });
