import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useWavesurfer } from "@wavesurfer/react";
import Timeline from "wavesurfer.js/dist/plugins/timeline.js";
import Region from "wavesurfer.js/dist/plugins/regions.js";

import {
  MAX_AUDIO_LENGTH,
  setDuration,
} from "../../../../store/audioAssetReducer";
import { useArtStyle, useAudioTrim } from "../../../../store/hooks";
import { max } from "wavesurfer.js/src/util";
import { Toast } from "react-bootstrap";

const MAX = MAX_AUDIO_LENGTH;

interface TrimPlayerProps {
  url: string;
  active: boolean;
  index: number;
}

export default function TrimPlayer(props: TrimPlayerProps) {
  const { url, active, index } = props;
  const { themeId } = useArtStyle();
  const containerRef = useRef(null);
  const [showAudioDurationExceededToast, setShowAudioDurationExceededToast] =
    useState(false);

  const wavesurferRef = useRef<any>(null);
  const selectedRegionRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { delta, setAudioDuration, setEnd } = useAudioTrim();
  const [region, setRegion] = useState<{ start: number; end: number }>({
    start: 0,
    end: MAX,
  });
  const [startEnd, setStartEnd] = useState<{ start: number; end: number }>({
    start: 0,
    end: MAX,
  });
  const [isPlayingState, setIsPlayingState] = useState(false);

  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    height: 100,
    waveColor: "#515151",
    progressColor: "#ff30c4",
    dragToSeek: true,
    barWidth: 3,
    barHeight: 1,
    cursorWidth: 2,
    cursorColor: "white",
    barGap: 3,
    url: url,

    // Remove the Timeline plugin to hide the timeline markings
    plugins: useMemo(() => [], []),
  });
  useEffect(() => {
    if (wavesurfer) {
      if (!wavesurferRef.current) {
        wavesurferRef.current = wavesurfer;
      }

      wavesurferRef.current.on("ready", () => {
        const duration = Math.min(wavesurferRef.current.getDuration(), MAX);

        if (delta.start === 0 && delta.end === MAX) {
          if (index === 0) {
            setEnd(duration);
          }
          setStartEnd({
            start: 0,
            end: duration,
          });
        } else {
          if (delta && themeId) {
            setStartEnd({
              start: delta.start,
              end: delta.end,
            });
            setAudioDuration({
              start: delta.start,
              end: delta.end,
            });
          } else {
            setStartEnd({
              start: 0,
              end: wavesurferRef.current.getDuration(),
            });
            setAudioDuration({
              start: delta.start,
              end: duration,
            });
          }
        }
        setIsLoading(false);

        const regionsPlugin = wavesurfer.registerPlugin(Region.create());
        if (!selectedRegionRef.current) {
          const selectedRegion = regionsPlugin.addRegion({
            id: "selected",
            start: 0,
            end: themeId ? delta.end : duration,
            color: "rgba(154, 154, 154, .4)",
            drag: true,
            resize: true,
          });
          selectedRegionRef.current = selectedRegion;
        }
        selectedRegionRef.current.on("update-end", () => {
          const updatedRegion =
            selectedRegionRef.current.start !== delta.start ||
            selectedRegionRef.current.end !== delta.end;
          if (updatedRegion) {
            const adjustedEnd = Math.min(selectedRegionRef.current.end, MAX);
            if (adjustedEnd !== selectedRegionRef.current.end) {
              setShowAudioDurationExceededToast(true);
            }
            setAudioDuration({
              start: selectedRegionRef.current.start,
              end: adjustedEnd,
            });
          }
        });
        selectedRegionRef.current.on("update", () => {
          const adjustedEnd = Math.min(selectedRegionRef.current.end, MAX);
          setStartEnd({
            start: selectedRegionRef.current.start,
            end: adjustedEnd,
          });
        });
      });
    }
  }, [wavesurfer]);

  useEffect(() => {
    if (delta.start !== region.start || delta.end !== region.end) {
      const adjustedEnd = Math.min(delta.end, MAX);
      const adjustedStart = Math.min(delta.start, MAX);
      setRegion({
        start: adjustedStart,
        end: adjustedEnd,
      });

      if (selectedRegionRef.current) {
        selectedRegionRef.current.start = adjustedStart;
        selectedRegionRef.current.end = adjustedEnd;
        selectedRegionRef.current.update = true;
      }
    }
  }, [delta]);

  useEffect(() => {
    if (selectedRegionRef.current) {
      wavesurferRef.current.pause();
      setIsPlayingState(false);
      const adjustedEnd = Math.min(delta.end, MAX);
      selectedRegionRef.current.start = delta.start;
      selectedRegionRef.current.end = adjustedEnd;
      selectedRegionRef.current._onUpdate(
        selectedRegionRef.current.update,
        "update"
      );
    }
  }, [active]);

  const onPlayPause = useCallback(() => {
    if (wavesurfer) {
      const { start, end } = region;

      if (isPlayingState) {
        wavesurfer.playPause();
        setIsPlayingState(false);
      } else {
        wavesurfer.seekTo(start / wavesurfer.getDuration());
        wavesurfer.play();
        setIsPlayingState(true);
      }
    }
  }, [wavesurfer, isPlayingState, region]);

  return (
    <div className={`${active ? "d-flex" : "d-none"} flex-column text-center`}>
      <div className="d-flex">
        {!isLoading && (
          <button onClick={onPlayPause} className="play-pause-btn">
            {isPlayingState ? (
              <i className="bi bi-pause"></i>
            ) : (
              <i className="bi bi-play"></i>
            )}
          </button>
        )}
        <div ref={containerRef} className="wavesurfer-container" />
      </div>
      {isLoading && <div style={{ height: "3rem" }}>Loading...</div>}
      {!isLoading && <Time {...{ startEnd }} />}
      {showAudioDurationExceededToast && (
        <AudioDurationExceededToast
          show={showAudioDurationExceededToast}
          onClose={() => setShowAudioDurationExceededToast(false)}
        />
      )}
    </div>
  );
}

function AudioDurationExceededToast(props: {
  show: boolean;
  onClose: () => void;
}) {
  const { show, onClose } = props;

  return (
    <Toast show={show} onClose={onClose}>
      <Toast.Header>
        <i
          className="bi bi-info-circle"
          style={{ fontSize: "1rem", marginRight: "8px" }}
        />
        <strong className="me-auto">Muze</strong>
      </Toast.Header>
      <Toast.Body>
        The audio duration cannot exceed 4 minutes. It has been trimmed
        accordingly.
      </Toast.Body>
    </Toast>
  );
}

function Time(props: any) {
  const { startEnd } = props;
  const t = formatSecondsAsTime(startEnd.start);
  const tt = formatSecondsAsTime(startEnd.end);
  return (
    <p className="small text-start text-muted p-0 m-0 mt-1 d-flex justify-content-between">
      <span>{t}</span>
      <span>{tt}</span>
    </p>
  );
}

function formatSecondsAsTime(secs: number) {
  let hr = Math.floor(secs / 3600);
  let min: any = Math.floor((secs - hr * 3600) / 60);
  let sec: any = Math.floor(secs - hr * 3600 - min * 60);

  if (min < 10) {
    min = "0" + min;
  }
  if (sec < 10) {
    sec = "0" + sec;
  }
  return min + ":" + sec;
}
