import axios from "axios";
import { useEffect, useState } from "react";
import { useCustomEventListener } from "react-custom-events";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../AuthProvider";
import PopUp from "../SharedComponents/PopUp";
import usePaginator from "../Paginator/usePaginator";
import Paginator from "../Paginator";
import ReactPlayer from "react-player";
import { APIClient } from "../../utils/services";
import { getInfo } from "react-mediainfo";

export default function VideosUploaded() {
  const [videos, setVideos] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasVideos, setHasVideos] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const paginator = usePaginator();
  const { actual, setTotal } = paginator;

  const { currentUser } = useAuth();
  const [search] = useSearchParams();
  const query = search.get("query");
  const ownerId = currentUser?.email || "";
  const { REACT_APP_MUZE_API_HOST, REACT_APP_RESTYLE_PROD_CDN_URL } =
    process.env;

  const page = actual;
  const perPage = 10;

  const deleteVideos = (name: string) => {
    const handleDelete = () => {
      axios
        .delete(`${REACT_APP_MUZE_API_HOST}/external-video/${ownerId}/${name}`)
        .then((res) => {
          const newData = videos.filter((el: any) => el.name !== name);
          setVideos(newData);
          setVideoData(newData);
        })
        .catch((err) => {
          console.log(err);
          setError(true);
        })
        .finally(() => {
          setShow(false);
        });
    };

    setModalInfo({
      shouldShow: true,
      title: "Delete video",
      text: "Are you sure you want to delete this video?",
      confirmText: "Delete",
      onConfirm: () => handleDelete(),
      onClose: () => setShow(false),
    });
    setShow(true);
  };

  const getVideos = () => {
    setLoading(true);
    axios
      .get(
        `${REACT_APP_MUZE_API_HOST}/external-video/assets?owner_id=${encodeURIComponent(
          ownerId
        )}&page=${page}&per_page=${perPage}`
      )
      .then((res) => {
        if (res.data.results.length === 0 || !Array.isArray(res.data.results)) {
          setLoading(false);
          setHasVideos(false);
          setTotal(0);
          return;
        }
        const formatedVideos = res.data.results.map((el: any) => {
          return {
            src: `${REACT_APP_RESTYLE_PROD_CDN_URL}/${encodeURIComponent(
              ownerId
            )}/original-videos/${encodeURIComponent(el.Key)}`,
            name: el.Key,
            date: new Date(el.LastModified).toLocaleDateString(),
            width: el.metadata.width,
            height: el.metadata.height,
          };
        });
        setVideos(formatedVideos);
        setVideoData(formatedVideos);
        setHasVideos(true);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useCustomEventListener("onVideoUpload", () => {
    getVideos();
  });

  useEffect(() => {
    getVideos();
  }, [actual]);

  useEffect(() => {
    setVideos(() =>
      videoData.filter((el: any) =>
        el.name.toLowerCase().includes(query?.toLowerCase())
      )
    );
  }, [query]);

  return (
    <div className="choose-from-library-mobile">
      <div className="library-img-conteiner overflow-auto">
        {show && <PopUp {...modalInfo} />}
        {error ? (
          "Ops, something went wrong :("
        ) : loading ? (
          <Spinner />
        ) : !hasVideos ? (
          "0 videos found."
        ) : (
          videos.map(({ name, src, date, width, height }, i) => (
            <Card
              name={name}
              src={src}
              date={date}
              loading={loading}
              setLoading={setLoading}
              width={width}
              height={height}
              key={`media-card-${i}`}
              deleteVideos={deleteVideos}
            />
          ))
        )}
      </div>

      <Paginator {...paginator} />
    </div>
  );
}

function Spinner() {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="spinner-border"
        style={{ width: "2rem", height: "2rem" }}
        role="status"
      />
    </div>
  );
}

function Card(props: {
  name: string;
  src: string;
  date: string;
  loading: any;
  setLoading: any;
  deleteVideos: any;
  width: any;
  height: any;
}) {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const [isLoadingFetch, setIsLoadingFetch] = useState(false);
  const { name, src, date, deleteVideos, loading, setLoading, width, height } =
    props;
  const isVideo =
    src.endsWith(".mp4") || src.endsWith(".avi") || src.endsWith(".mov");

  const handleDeleteClick = (event: any) => {
    event.stopPropagation();
    deleteVideos(name);
  };

  const handleVideoClick = async () => {
    try {
      setIsLoadingFetch(true);
      const response = await fetch(src);
      if (!response.ok) {
        setIsLoadingFetch(false);
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }

      const videoBlob = await response.blob();
      const videoInfo = await getInfo(videoBlob);

      const videoTrack = videoInfo?.media?.track?.find(
        (track) => track["@type"] === "Video"
      );

      if (videoTrack) {
        setIsLoadingFetch(false);
        navigate("/app/restyle", {
          state: {
            videoInfo: {
              url: src,
              name: name,
              width: width || videoTrack?.Width,
              height: height || videoTrack?.Height,
            },
          },
        });
      } else {
        setIsLoadingFetch(false);
        console.error("Dimensions not found");
      }
    } catch (error) {
      setIsLoadingFetch(false);
      console.error("Info error:", error);
    }
  };

  const cardStyle = {
    width: "10rem",
    height: "10rem",
    border: "3px solid #ccc",
    transition: "border-color 0.3s ease",
    cursor: "pointer",
    borderRadius: "0.5rem",
    borderColor: hovered ? "#ff69b4" : "#ccc",
    position: "relative" as "relative",
    overflow: "hidden",
  };

  const deleteIconStyle = {
    position: "absolute" as "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer",
    zIndex: 1,
  };

  const nameStyle: React.CSSProperties = {
    position: "absolute",
    bottom: "5px",
    left: "5px",
    right: "5px",
    color: "#fff",
    padding: "2px 5px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "0.8rem",
  };

  const dateStyle: React.CSSProperties = {
    position: "relative",
    left: "5px",
    right: "5px",
    color: "#fff",
    padding: "2px 5px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "0.8rem",
  };

  return (
    <div
      className="rounded text-center"
      style={cardStyle}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div style={dateStyle}>{date}</div>
      <i
        className="bi bi-trash"
        style={deleteIconStyle}
        onClick={handleDeleteClick}
      ></i>
      {loading || isLoadingFetch ? (
        <Spinner />
      ) : isVideo ? (
        <ReactPlayer
          url={src}
          width="100%"
          height="100%"
          style={{ maxHeight: "11rem" }}
          onClick={handleVideoClick}
        />
      ) : (
        <img className="library-img" src={src} alt={name} />
      )}
      <div style={nameStyle}>{name}</div>
    </div>
  );
}
