import { useEffect, useState } from "react";
import { APIClient } from "../../../utils/services";
import {
  AudioContent,
  AudioReactiveConfig,
  DeforumAnimation,
  StrengthPromptCompensation,
  TempAudio,
  Transition,
  VideoBuildRequest,
  VideoRequest,
  VideoStyle,
} from "../types";
import {
  useAdvancedSettings,
  useAnimationModeStore,
  useArtStyle,
  useAspectRatioStore,
  useAudioAsset,
  useAudioTrim,
  useAvatar,
  useBoomerangStore,
  useClearConfigs,
  useGenerationLimits,
  useStartingFrame,
  useStrengthPromptCompensation,
  useSubjectMatter,
  useTempAudio,
  useTransitions,
  useVocalShakeStore,
  useZoomPulseStore,
  useAutomaticTimeStore,
  useNegativePrompts,
  useMotionStrengthStore,
  useNoAudio,
  useSpotify,
} from "../../../store/hooks";
import { TransitionValues } from "../AudioPage/MSetTransitionsEffects/types";
import { useAuth } from "../../../AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { checkSimultaneousGeneration, validTime } from "../../../utils/utils";
import { formatMinutes, formatSeconds } from "../Prompts/LyricsBox";
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";

const API_KEY = process.env.REACT_APP_MUZE_API_KEY;
// const BUCKET = "muze-mvp-dev-dreambooth-images-public"; // TODO: Should come from an env var
const BUCKET = process.env.REACT_APP_IMAGES_BUCKET;

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "";
mixpanel.init(MIXPANEL_TOKEN);

export default function GenerateVideoBtn({
  setShowModalPurchase,
  setShowModalRemain,
  isFromSpotify = false,
}: {
  setShowModalPurchase: any;
  setShowModalRemain: any;
  isFromSpotify?: boolean;
}) {
  const navigate = useNavigate();
  const client = new APIClient();
  const location = useLocation();
  const { state } = location;
  const [isGen, setIsGen] = useState(false);

  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";

  let watermark = false;

  client.getUserByEmail(ownerId).then((res: any) => {
    const result = res?.account_type;
    if (result === "free" || ownerId === "demo@mymuze.art") {
      watermark = true;
    } else {
      watermark = false;
    }
  });

  const { avatar } = useAvatar();
  const { themeId } = useArtStyle();
  const { transit } = useTransitions();
  const { subject } = useSubjectMatter();
  const { start, end } = useAudioTrim();
  const { selectedMusic } = useAudioAsset();
  const { aspectRatio } = useAspectRatioStore();
  const { animationMode } = useAnimationModeStore();
  const { subjectMatter, initialImageKey, videoName } = useStartingFrame();
  const { automaticTime } = useAutomaticTimeStore();
  const { enableNegativePrompts } = useNegativePrompts();
  const { strengthPromptCompensation } = useStrengthPromptCompensation();
  const { tempAudioParams: initialTempAudioParams } = useTempAudio();
  const { no_audio } = useNoAudio();
  const { spotify } = useSpotify();
  const { setinProgressCount, inProgress } = useGenerationLimits();
  const { clear } = useClearConfigs();

  const audio = newAudioConfig({
    audio_asset_id: selectedMusic.asset_id || null,
    start_time: selectedMusic.asset_id ? start : start, // should be setted in ui
    stop_time: selectedMusic.asset_id ? end : end, // should be setted in ui
    name: selectedMusic.name || null,
  } as AudioContent);

  const isSpotify = spotify == true;

  const tempAudioParams = isSpotify
    ? {
        name: "",
        key: "",
        delete_source: false,
      }
    : initialTempAudioParams;

  const orientation = isSpotify
    ? "vertical"
    : aspectRatio !== ""
    ? aspectRatio.toLowerCase()
    : "square";

  const style = newStyleConfig({
    number_of_prompts: 1,
    theme_id: themeId,
    subject_matter: [subjectMatter],
    subject_matter_time: [],
    negative_prompts_choosen: [],
    dreambooth_id: avatar.id === "" ? null : avatar.id,
    orientation,
  } as VideoStyle);
  const predefinedThemes = [
    "Photorealistic",
    "Illustration",
    "Gritty",
    "Cyberpunk",
    "Vibrant",
    "Indie",
    "Psychedelic",
    "Steampunk",
    "Manga",
    "Cartoon",
    "Surreal",
    "Pop",
  ];

  let isCustomStyle = !predefinedThemes.includes(themeId);

  const { cadence, strength, steps, seed, fps } = useAdvancedSettings();
  const animation = newAnimationConfig({
    initial_image_key:
      initialImageKey !== undefined && initialImageKey !== ""
        ? initialImageKey ===
            "portrait of a rap artist set against a neon-lit cityscape.png" ||
          initialImageKey === "girl with pink hair, vaporwave style,.png" ||
          initialImageKey ===
            "hispanic-female-enjoying-music-with-headphones-and-2023-11-27-05-27-01-utc.jpg" ||
          initialImageKey === "5.jpg" ||
          initialImageKey ===
            "Comic book cover of blue eyes with pinks face, in the style of hauntingly beautiful illustrations, realistic hyper — detail, glowwave, bold lithographic, dotted, iconic, quietly morbid, 32k cel shading.png"
          ? `temp/demo@mymuze.art/${initialImageKey}`
          : `temp/${ownerId}/${initialImageKey}`
        : null,
    initial_image_bucket:
      initialImageKey !== undefined && initialImageKey !== "" ? BUCKET : null,
    initial_image_strength: initialImageKey === "" ? undefined : 1.0,
    animation_mode: animationMode !== "" ? animationMode : "2D",
    diffusion_cadence: cadence,
    strength,
    steps,
    seed,
    fps,
    duration: (isSpotify ? 4 : end - start) || 30,
  } as DeforumAnimation);

  const transitions: Transition[] = [
    ...transit
      .filter((i: TransitionValues) => i.transition > -1)
      .map(
        (i: TransitionValues) =>
          ({
            type: i.type,
            strength: i.strength,
            offset_start: i.offsetStart,
            duration: i.duration,
          } as Transition)
      ),
  ];

  const subjectMatters = subject.map((el: any) => {
    if (automaticTime) {
      return el.label.toLowerCase();
    } else {
      return el.label.toLowerCase();
    }
  });

  const negativePromptsChoosen = subject.map(
    (el: any) => el.negativePrompt?.toLowerCase() || ""
  );

  const subjectMattersTime = !automaticTime
    ? subject.map((el: any) => el.time)
    : [];

  const { boomerang } = useBoomerangStore();
  const build: VideoBuildRequest = {
    reverse_loop: isSpotify ? true : boomerang,
  };

  const { zoomPulse } = useZoomPulseStore();
  const { vocalShake } = useVocalShakeStore();
  const { motionStrength } = useMotionStrengthStore();
  const audioReactivity: AudioReactiveConfig = newAudioReactivityConfig({
    enable_zoom_pulse_for_drums: isSpotify ? false : zoomPulse,
    enable_vocal_shake: isSpotify ? false : vocalShake,
    constant_motion_strength: isSpotify ? true : motionStrength,
    sync_prompt_changes_to_segment_boundaries:
      isSpotify || no_audio ? false : true,
  });

  const gv = async () =>
    generateVideo({
      ownerId,
      videoName,
      watermark,
      isCustomStyle,
      subjectMatters,
      subjectMattersTime,
      negativePromptsChoosen,
      audio,
      style,
      animation,
      build,
      transitions,
      audioReactivity,
      strengthPromptCompensation,
      tempAudioParams,
      no_audio,
      spotify,
      setIsGen,
      // callback: () => +navigate("/app/library/projects") + +clear(),
    });
  const onClick = async () => {
    if (!automaticTime) {
      for (let i = 0; i < subject.length; i++) {
        const prompt = subject[i];
        if (!validTime(subject, formatSeconds(prompt.time), i, end)) {
          let startTime = i === 0 ? "00:00" : subject[i - 1].time;
          let endTime =
            i === subject.length - 1 ? formatMinutes(end) : subject[i + 1].time;
          toast.error(
            `The timing of the prompt "${prompt.label}" must be between ${startTime} and ${endTime}`,
            { duration: 5000 }
          );
          return;
        }
      }
    }

    setIsGen(true);
    try {
      const { canProceed, responseHandlerData } = await gv();
      setinProgressCount(inProgress + 1);

      if (!canProceed) {
        setShowModalPurchase(true);

        return;
      }
      if (!responseHandlerData) {
        setShowModalRemain(true);

        return;
      }

      setTimeout(() => {
        navigate("/app/library/projects");
        clear();
      }, 3000);
    } catch (error) {
      Sentry.captureException(error);
      Sentry.setUser({ email: ownerId });
      console.error("Error generating video:", error);
      toast.error("Error generating video", { duration: 5000 });
      return;
    }
  };

  const btn = isGen ? <GeneratingBtn /> : <GenerateBtn {...{ onClick }} />;

  if (themeId === "") return <ErrorStyleBtn />;
  if (videoName === "") return <ErrorNameBtn />;
  if (!/^[a-zA-Z0-9\s!@#$%^&*()_+={}[\]:;<>,.?~\\/-]+$/.test(videoName))
    return <ErrorNameNonEnglishBtn />;
  if (!/^[a-zA-Z0-9\s]*$/.test(videoName))
    return <ErrorContainsSpecialCharacter />;

  return <>{btn}</>;
}

const isMobileDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /mobile/.test(userAgent);
};

function GenerateBtn(props: any) {
  const { onClick } = props;
  const className = "btn btn-success mt-auto";
  return (
    <button
      style={isMobileDevice() ? { fontSize: "small" } : {}}
      children={"Generate Video"}
      onClick={onClick}
      className={className}
    />
  );
}
function GeneratingBtn() {
  const className = "btn btn-success mt-auto";
  return (
    <button
      style={isMobileDevice() ? { fontSize: "small" } : {}}
      disabled
      children={"Generating Video"}
      className={className}
    />
  );
}

function ErrorStyleBtn() {
  const n = useNavigate();
  const className = "btn btn-danger mt-auto";
  const children = "Choose Art Style first";
  const onClick = () => n("/app/generate-video/style");
  return (
    <button
      style={isMobileDevice() ? { fontSize: "small" } : {}}
      {...{ className, children, onClick }}
    />
  );
}

function ErrorNameBtn() {
  const n = useNavigate();
  const className = "btn btn-danger mt-auto";
  const children = "Name Your Video";
  const onClick = () => n("/app/create-video");
  return (
    <button
      style={isMobileDevice() ? { fontSize: "small" } : {}}
      {...{ className, children, onClick }}
    />
  );
}

function ErrorNameNonEnglishBtn() {
  const n = useNavigate();
  const className = "btn btn-danger mt-auto";
  const children = '"Video Name" contains non-english characters';
  const onClick = () => n("/app/create-video");
  return (
    <button
      style={isMobileDevice() ? { fontSize: "small" } : {}}
      {...{ className, children, onClick }}
    />
  );
}

function ErrorContainsSpecialCharacter() {
  const n = useNavigate();
  const className = "btn btn-danger mt-auto";
  const children = '"Video Name" contains special characters';
  const onClick = () => n("/app/create-video");
  return (
    <button
      style={isMobileDevice() ? { fontSize: "small" } : {}}
      {...{ className, children, onClick }}
    />
  );
}

const defaultAudio: AudioContent = {
  auto_invert: false,
  motion_compensation_method: "maui",
  records: [],
  strength_adjust_for_motion: true,
};

const defaultStyle: VideoStyle = {
  orientation: "square",
  subject_matter: [],
  subject_matter_time: [],
  negative_prompts_choosen: [],
  theme_id: "Cyberpunk",
};

const defaultPromptCompensation: StrengthPromptCompensation = {
  enabled: true,
  duration: 0.3,
  strength: 0.2,
};

const defaultAnimation: DeforumAnimation = {
  duration: 10.0,
  fps: 24,
  anti_blur: 0.75,
  seed: 0, // Math.round(Math.random() * 65535),
  cfg: 14,
  noise: 0.2,
  contrast: 1.0,
  strength: 0.6,
  diffusion_cadence: 3,
  steps: 35,
  animation_mode: "2D",
  sampler: "default",
  initial_image_key: "Dreambooth/lexcasa@gmail.com/raw/05_c62e1a.jpeg",
  initial_image_bucket: "muze-mvp-prod-images-public",
  initial_image_strength: 0.7,
  fov: 70.0,
};

const defaultAudioReactivity: AudioReactiveConfig = {
  constant_motion_strength: 0.5,
  enable_constant_motion_segments: true,
  enable_transitions_on_segment_boundaries: false,
  enable_vocal_shake: true,
  enable_zoom_pulse_for_drums: true,
  min_segment_duration: 6,
  sync_prompt_changes_to_segment_boundaries: true,
};

const defaultTransitions: Transition[] = [];

function newAudioConfig(music: AudioContent) {
  return { ...defaultAudio, ...music } as AudioContent;
}

function newStyleConfig(style: VideoStyle) {
  return { ...defaultStyle, ...style } as VideoStyle;
}

function newAnimationConfig(animation: DeforumAnimation) {
  return { ...defaultAnimation, ...animation } as DeforumAnimation;
}

function newTransitionConfig(transition: Transition) {
  return transition as Transition;
}

function newAudioReactivityConfig(reactivity: {}): AudioReactiveConfig {
  return { ...defaultAudioReactivity, ...reactivity };
}

async function generateVideo({
  ownerId,
  videoName,
  watermark,
  isCustomStyle,
  subjectMatters,
  subjectMattersTime,
  negativePromptsChoosen,
  callback = () => {},
  audio = defaultAudio,
  style = defaultStyle,
  animation = defaultAnimation,
  strengthPromptCompensation = defaultPromptCompensation,
  transitions = defaultTransitions,
  build = { reverse_loop: false } as VideoBuildRequest,
  audioReactivity = defaultAudioReactivity,
  tempAudioParams = {} as TempAudio,
  no_audio,
  spotify,
  setIsGen,
}: {
  ownerId: string;
  videoName: string;
  watermark: boolean;
  isCustomStyle: boolean;
  subjectMatters: string[];
  subjectMattersTime: string[];
  negativePromptsChoosen: string[];
  callback?: Function;
  audio?: AudioContent;
  style?: VideoStyle;
  animation?: DeforumAnimation;
  transitions?: Transition[];
  build: VideoBuildRequest;
  audioReactivity: AudioReactiveConfig;
  tempAudioParams: TempAudio;
  no_audio: boolean;
  spotify: boolean;
  strengthPromptCompensation: StrengthPromptCompensation;
  setIsGen: Function;
}): Promise<{ canProceed: boolean; responseHandlerData: boolean }> {
  return new Promise(async (resolve) => {
    const vg: VideoRequest = {
      animation: animation,
      watermark: watermark,
      isCustomStyle: isCustomStyle,
      audio,
      audio_reactive_config: audioReactivity,
      build,
      create_preview: false,
      style: {
        ...style,
        subject_matter: subjectMatters,
        subject_matter_time: subjectMattersTime,
        negative_prompts_choosen: negativePromptsChoosen,
      },
      transitions: transitions as Transition[],
      strength_prompt_compensation: strengthPromptCompensation,
      video_name: videoName,
      temp_audio: tempAudioParams,
      no_audio: no_audio,
      spotify: spotify,
    };

    // round start_time and stop_time to max two decimals
    if (vg?.audio?.start_time) {
      vg.audio.start_time = Math.round(vg.audio.start_time * 100) / 100;
    }
    if (vg?.audio?.stop_time) {
      vg.audio.stop_time = Math.round(vg.audio.stop_time * 100) / 100;
    }

    const canProceed = await checkSimultaneousGeneration(ownerId);
    if (!canProceed) {
      resolve({ canProceed: false, responseHandlerData: true });
      return;
    }

    if (
      !Array.isArray(subjectMatters) ||
      subjectMatters.length === 0 ||
      !subjectMatters.some((item) => item.trim() !== "")
    ) {
      toast.error(
        "Please provide at least one valid positive prompt to proceed",
        {
          duration: 5000,
        }
      );
      setIsGen(false);
      return;
    }

    const properties: { [key: string]: boolean | string } = {};
    if (
      animation.initial_image_key &&
      animation.initial_image_key.includes("demo@mymuze.art")
    ) {
      properties.fromTemplate = true;
      properties.fromImageToVideoFlow = false;
    } else {
      properties.fromTemplate = false;
      properties.fromImageToVideoFlow = animation.initial_image_key
        ? true
        : false;
    }

    properties.withAvatar = style.dreambooth_id ? true : false;
    properties.fromCreateVideoFlow = !(
      properties.fromTemplate || properties.fromImageToVideoFlow
    );

    mixpanel.track("Generate Video", properties);

    properties.withAvatar = style.dreambooth_id ? true : false;
    properties.fromCreateVideoFlow = !(
      properties.fromTemplate || properties.fromImageToVideoFlow
    );

    mixpanel.track("Generate Video", properties);

    const responseHandler = (res: any) => {
      if (res?.status && res.status === "ERROR") {
        resolve({ canProceed: true, responseHandlerData: false });
      } else {
        toast.success("Generating new video", { duration: 7000 });
        callback();
        resolve({ canProceed: true, responseHandlerData: true });
      }
    };

    const client = new APIClient();
    client.authenticate(undefined, undefined, API_KEY);
    console.log("video_generation :: ", JSON.stringify(vg), animation);
    client.generate_video(ownerId, vg, (r) => responseHandler(r));
  });
}
