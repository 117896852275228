import { CSSProperties, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../AuthProvider";
import PopUp from "../SharedComponents/PopUp";
import usePaginator from "../Paginator/usePaginator";
import Paginator from "../Paginator";
import ReactPlayer from "react-player";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import {
  useGetRestyleMetadataQuery,
  useGetVideosUpscaleAllQuery,
  useReloadVideosUpscaleQuery,
} from "../../API";
import toast from "react-hot-toast";
import { APIClient } from "../../utils/services";

const BASE_URL = process.env.REACT_APP_S3_BUCKET_UPSCALE_VIDEOS_PUBLIC;

export default function RestyledUploaded() {
  const [videos, setVideos] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [projects, setProjects] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(-1);
  const [deleteProjectId, setDeleteProjectId] = useState("");
  const [hasVideos, setHasVideos] = useState(false);
  const [error, setError] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [modalVideoUrl, setModalVideoUrl] = useState("");
  const [nameVideo, setNameVideo] = useState("");
  const [promptPositive, setPromptPositive] = useState("");
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [upscaledVideos, setUpscaledVideos] = useState<any[]>([]);
  const [upscaleParams, setUpscaleParams] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRestyle, setIsLoadingRestyle] = useState(true);
  const paginator = usePaginator();
  const { actual, setTotal } = paginator;

  const { currentUser } = useAuth();
  const [search] = useSearchParams();
  const query = search.get("query");
  const ownerId = currentUser?.email || "";
  const { REACT_APP_MUZE_API_HOST, REACT_APP_RESTYLE_PROD_CDN_URL } =
    process.env;

  const page = actual;
  const perPage = 10;

  const navigate = useNavigate();

  const { data, isSuccess, refetch } = useGetRestyleMetadataQuery({
    ownerId,
  });

  const { data: dataUpscaleQuery } = useReloadVideosUpscaleQuery({
    ownerId,
  });

  const { data: dataUpscale } = useGetVideosUpscaleAllQuery({
    ownerId,
  });

  const onDelete = (id: string) => {
    setDeleteProjectId(id);
    setShowModal(true);
  };

  const modal = {
    title: "Delete Video",
    text: "Are you sure you want to delete this video?",
    confirmText: "Confirm",
    shouldShow: showModal,
    onConfirm: () => {
      const client = new APIClient();
      client.authenticate(
        undefined,
        undefined,
        process.env.REACT_APP_MUZE_API_KEY
      );
      console.log("delete Project: ", deleteProjectId);
      client.restyle_delete(ownerId, deleteProjectId, () => refetch());
      setDeleteProjectId("");
      setShowModal(false);
      setShow(-1);
    },
    onClose: () => {
      setDeleteProjectId("");
      setShowModal(false);
    },
  };

  useEffect(() => {
    if (dataUpscale) {
      const upscaleData = dataUpscale.result || [];
      const videos = upscaleData
        .map((video: any) => ({
          idUps: video.video_id,
          statusUps: video.UPSCALE_status,
          okUps: video.UPSCALE_status === "DONE",
          errorUps: video.UPSCALE_status === "ERROR",
          progressUps: mapStatusToProgressUps(video.UPSCALE_status),
          dateUps: new Date(video.created_at).toLocaleDateString(),
          lastUpdateUps: new Date(video.created_at),
        }))
        .sort((a: any, b: any) => b.lastUpdateUps - a.lastUpdateUps);
      setUpscaledVideos(videos);
    }
  }, [dataUpscale]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const p = data?.result || [];
      if (upscaledVideos.length > 0 || p.length > 0) {
        const updatedProjects = p.map((i: any) => {
          const correspondingUpscaleItem = dataUpscaleQuery?.contents?.find(
            (upscaleItem: any) => upscaleItem.id_upscale === i.video_id
          );

          const getEmailEncodedKey = (key: any) => {
            const [email, videoId] = key.split("/");
            const encodedEmail = encodeURIComponent(email);
            return `${encodedEmail}/${videoId}`;
          };

          const url =
            correspondingUpscaleItem?.id_upscale === i.video_id
              ? BASE_URL +
                getEmailEncodedKey(correspondingUpscaleItem.Key)
                  .split(" ")
                  .join("+")
              : `${REACT_APP_RESTYLE_PROD_CDN_URL}/${encodeURIComponent(
                  ownerId
                )}/videos/${i.video_id}.mp4`;

          const hasVideo = p.length > 0;
          const isUpscaleDone =
            correspondingUpscaleItem?.id_upscale === i.video_id;

          if (hasVideo) {
            setHasVideos(true);
          }

          return {
            id: i.video_id,
            okUps: upscaledVideos.find((v: any) => v.idUps === i.video_id)
              ?.okUps,
            idUps: upscaledVideos.find((v: any) => v.idUps === i.video_id)
              ?.idUps,
            statusUps: upscaledVideos.find((v: any) => v.idUps === i.video_id)
              ?.statusUps,
            progressUps: upscaledVideos.find((v: any) => v.idUps === i.video_id)
              ?.progressUps,
            dateUps: upscaledVideos.find((v: any) => v.idUps === i.video_id)
              ?.dateUps,
            errorUps: upscaledVideos.find((v: any) => v.idUps === i.video_id)
              ?.errorUps,
            name: i.video_name,
            prompt: i.restyle_settings,
            ownerId: i.owner_id,
            date: new Date(i.created_at).toLocaleDateString(),
            lastUpdate: new Date(i.created_at),
            isError: i.restyle_status === "ERROR",
            progress: mapStatusToProgress(i.restyle_status),
            statusVideo: i.restyle_status,
            stateMachine: i.restyle_process_arn,
            errorTimeOut: i.restyle_status === "States.Timeout",
            statusUpscaleDone: isUpscaleDone ? "UPSCALING DONE" : undefined,
            statusUpscaleInProgress: "IN PROGRESS",
            statusUpscaleError: "ERROR IN UPSCALING PROCESS",
            ok: i.restyle_status === "DONE",
            icon:
              i.restyle_status === "DONE"
                ? "file-earmark-play"
                : i.restyle_status == "PROCESSING"
                ? "bi bi-clock-history"
                : "bi bi-exclamation-triangle-fill",
            url: url,
            hasVideo: hasVideo,
            show,
            setShow,
            onDelete,

            onClick: () =>
              handleVideoClick(
                url,
                i.video_name,
                i.restyle_settings,
                i.video_id,
                i.restyle_process_arn
              ),
          };
        });
        setProjects(
          updatedProjects
            .filter(
              (i: any) =>
                !query ||
                query === "" ||
                i.video_id.toLowerCase().includes(query.toLowerCase()) ||
                i.restyle_status.toLowerCase().includes(query)
            )
            .sort((a: any, b: any) => b.lastUpdate - a.lastUpdate) // newest first
            .map((j: any, k: number, l: any) => (
              <Card key={k} {...{ ...j, k, l }} />
            ))
        );
        refetch();
      }
      setIsLoading(false);
      setIsLoadingRestyle(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [upscaledVideos, data, show, query]);

  useEffect(() => {
    setVideos(() =>
      videoData.filter((el: any) =>
        el.name.toLowerCase().includes(query?.toLowerCase())
      )
    );
  }, [query]);

  const handleVideoClick = (
    url: string,
    name: string,
    prompt: string,
    videoId: string,
    stateMachine: string | null
  ) => {
    setModalVideoUrl(url);
    setNameVideo(name);
    setPromptPositive(prompt);
    setUpscaleParams({ url, name, videoId, stateMachine });
    setShowVideoModal(true);
  };

  const handleClose = () => setShowVideoModal(false);

  const downloadVideo = async (url: string, filename: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleUpscale = (navigate: Function) => {
    if (upscaleParams) {
      const { url, name, videoId, stateMachine } = upscaleParams;
      upScale(
        videoId,
        navigate,
        url,
        name,
        ownerId,
        stateMachine,
        { ok: true },
        true
      );
    }
  };

  return (
    <div>
      <div className="library-img-container p-3">
        {show && <PopUp {...modalInfo} />}
        {error ? (
          "Ops, something went wrong :("
        ) : isLoadingRestyle || isLoading ? (
          <Spinner />
        ) : !hasVideos ? (
          "0 videos found."
        ) : (
          projects
        )}
      </div>
      <PopUp {...modal} />
      <div className="paginator-container">
        <Paginator {...paginator} />
      </div>
      <Modal show={showVideoModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Video Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactPlayer url={modalVideoUrl} controls width="100%" />
          <p className="mt-4">{promptPositive}</p>
        </Modal.Body>
        <Modal.Footer>
          <div
            onClick={() => downloadVideo(modalVideoUrl, nameVideo)}
            className="create-video-btn btn"
          >
            <button
              style={{
                border: "none",
                backgroundColor: "transparent",
                alignContent: "center",
                marginRight: "5px",
                color: "#fff",
                fontWeight: "bold",
              }}
              disabled={loading}
            >
              Download
            </button>
            <i
              className="bi bi-cloud-download-fill"
              style={{
                fontSize: "20px",
                borderRadius: "10px",
                color: "#fff",
                fontWeight: "bold",
              }}
            ></i>
          </div>
          {/* <div
            onClick={() => handleUpscale(navigate)}
            className="btn btn-success"
          >
            <button
              style={{
                border: "none",
                backgroundColor: "transparent",
                alignContent: "center",
                marginRight: "5px",
                color: "#fff",
                fontWeight: "bold",
              }}
              disabled={loading}
            >
              Upscale
            </button>
            <i
              className="bi bi-display"
              style={{
                fontSize: "20px",
                borderRadius: "10px",
                color: "#fff",
                fontWeight: "bold",
              }}
            ></i>
          </div> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function mapStatusToProgress(status: any) {
  switch (status) {
    case "DONE":
      return 16;
    case "IN PROGRESS":
      return 12;
    case "SUBMITTED":
      return 8;
  }
}

function mapStatusToProgressUps(status: any) {
  switch (status) {
    case "DONE":
      return 16;
    case "IN PROGRESS":
      return 12;
    case "SUBMITTED":
      return 8;
  }
}

function Spinner() {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="spinner-border"
        style={{ width: "2rem", height: "2rem" }}
        role="status"
      />
    </div>
  );
}

async function upScale(
  videoId: string,
  navigate: Function,
  url: string,
  name: string,
  ownerId: string,
  stateMachine: string | null,
  options: { ok: boolean },
  isRestyledVideo: boolean
) {
  try {
    navigate("/app/image-to-video/preview-video", {
      state: {
        videoInfo: {
          url,
          name,
          ownerId,
          id: videoId,
          ok: options.ok,
          stateMachine,
          isRestyledVideo,
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
}

function Card(props: any) {
  const navigate = useNavigate();
  const {
    name,
    url,
    hasVideo,
    progress,
    statusVideo,
    isError,
    stateMachine,
    statusUpscaleInProgress,
    idUps,
    id,
    statusUps,
    okUps,
    date,
    dateUps,
    errorUps,
    errorTimeOut,
    statusUpscaleError,
    statusUpscale,
    statusUpscaleDone,
    progressUps,
    onDelete,
    ok,
    ownerId,
    onClick,
  } = props;

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const cardStyle: CSSProperties = {
    width: isMobileDevice() ? "9rem" : "10rem",
    height: isMobileDevice() ? "10rem" : "14rem",
    border: "3px solid #ccc",
    transition: "border-color 0.3s ease",
    cursor: "pointer",
    borderRadius: "0.5rem",
    position: "relative",
    overflow: "hidden",
    margin: "0.3rem",
  };

  const nameStyle: CSSProperties = {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    backgroundColor: "transparent",
    color: "#fff",
    padding: "0.5rem",
    fontSize: "0.75rem",
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const showUpscaleProgressBar = idUps && statusUps !== "ERROR" && !okUps;
  const unshowUpscaleProgressBar = idUps && statusUps === "ERROR" && !okUps;
  const statusUpscaleClass = statusUpscaleDone
    ? "status-upscale-done-border"
    : "";

  const service = new APIClient();

  const handleCardClick = () => {
    if (isMobileDevice()) {
      upScale(
        id,
        navigate,
        url,
        name,
        ownerId,
        stateMachine,
        { ok: true },
        true
      );
    } else {
      onClick();
    }
  };

  return (
    <div
      className={`flex-column rounded border text-center p-1 btn ${statusUpscaleClass}`}
      style={cardStyle}
      onClick={handleCardClick}
    >
      <>
        {statusVideo === "IN PROGRESS" && (
          <div className="position-absolute top-50 start-50 translate-middle">
            <i className="bi bi-clock-history fs-1"></i>
          </div>
        )}
        {isError && (
          <div className="position-absolute top-50 start-50 translate-middle">
            <i className="bi bi-exclamation-triangle-fill text-danger fs-1"></i>
            <span>ERROR</span>
          </div>
        )}

        {errorTimeOut && (
          <div className="position-absolute top-50 start-50 translate-middle">
            <i className="bi bi-clock-history text-warning fs-1"></i>
            <span>TIMEOUT ERROR</span>
          </div>
        )}

        <div style={{ position: "relative", height: "100%", width: "100%" }}>
          {statusVideo === "IN PROGRESS" && (
            <ProgressBar
              striped
              animated
              max={20}
              now={progress}
              label={statusVideo}
            />
          )}
          {showUpscaleProgressBar && !unshowUpscaleProgressBar && (
            <ProgressBar
              striped
              animated
              variant="info"
              max={16}
              now={progressUps}
              label={statusUpscaleInProgress}
              style={{ width: "100%" }}
            />
          )}
          <div>{statusUpscaleDone}</div>
          {date && !idUps && !isNaN(new Date(date).getTime()) ? (
            <div>{date}</div>
          ) : (
            ""
          )}
          {idUps && !isNaN(new Date(dateUps).getTime()) ? (
            <div>{dateUps}</div>
          ) : (
            ""
          )}

          {!unshowUpscaleProgressBar && (
            <ReactPlayer
              url={url}
              width="100%"
              height="100%"
              style={{
                visibility: isError ? "hidden" : "visible",
                maxHeight: "12rem",
              }}
            />
          )}
        </div>

        <div style={{ marginTop: "5px" }}>
          <p className="m-0 p-0" style={{ fontSize: "0.65rem" }}>
            {showUpscaleProgressBar
              ? statusUpscale
              : okUps
              ? statusUpscaleDone
              : unshowUpscaleProgressBar
              ? statusUpscaleError
              : statusVideo}
          </p>
        </div>

        <div className="position-absolute top-0 start-3 end-0 mt-5 me-1">
          {(ok || isError || errorTimeOut) && !showUpscaleProgressBar && (
            <button
              className="btn-circle-white"
              title="Remove"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(id);
              }}
            >
              <i className="bi bi-trash"></i>
            </button>
          )}
          {ok &&
            !showUpscaleProgressBar &&
            statusUpscaleDone !== "UPSCALING DONE" && (
              <button
                className="btn-circle-white mt-2"
                title="Upscale"
                onClick={(e) => {
                  e.stopPropagation();
                  upScale(
                    id,
                    navigate,
                    url,
                    name,
                    ownerId,
                    stateMachine,
                    { ok: true },
                    true
                  );
                }}
              >
                <i className="bi bi-display"></i>
              </button>
            )}
        </div>
      </>

      <div style={nameStyle}>{name}</div>
    </div>
  );
}
